import { ApiResponse, ApiSingleResponse } from "../../../models/common";
import { Facility } from "../../checkout/models/api/booking";
import {
  BookableEntityType,
  FacilityAmenity,
  FacilityAmenityUpdateRequest,
  FacilityCreatePaymentInfoRequest,
  type FacilityCreateRequest,
  type FacilityQTCourt,
  FacilityUpdateBookableEntities,
  FacilityUpdateBookableEntityTypes,
  FacilityUpdateOpenHoursRequest,
  FacilityUpdateRequest,
  type FacilityVendor,
  FacilityWithUtc,
  SportType,
} from "../models/Facility";

import { formatFacility } from "../../checkout/helpers/typesMapping";

import { fetchApi, upload } from "../../../services/legacyApiClient";

const apiVersion = "customer/v2";

export const getFacilities = async (): Promise<
  ApiResponse<FacilityWithUtc>
> => {
  const response = (await fetchApi({
    uri: `${apiVersion}/facility`,
  })) as ApiResponse<Facility>;

  return {
    ...response,
    data: response.data.map(formatFacility),
  };
};

export const getPaginatedFacilities = async (
  lastPageItemId: string | null,
  pageSize: number | null,
  signal?: AbortSignal,
): Promise<ApiResponse<FacilityWithUtc>> => {
  const response = (await fetchApi({
    uri: `${apiVersion}/facility/all/paginated`,
    method: "POST",
    payload: {
      lastPageItemId,
      pageSize,
    },
    signal,
  })) as ApiResponse<Facility>;

  return {
    ...response,
    data: response.data.map(formatFacility),
  };
};

export const getFacilitiesByIds = async (
  ids: string[],
  signal?: AbortSignal,
): Promise<ApiResponse<FacilityWithUtc>> => {
  const response = (await fetchApi({
    method: "POST",
    uri: `${apiVersion}/facility/list`,
    payload: {
      facilityIds: ids,
    },
    signal,
  })) as ApiResponse<Facility>;

  return {
    ...response,
    data: response.data.map(formatFacility),
  };
};
export const getAllFacilitiesForSuperadmin = async (
  page: number,
  pagesize: number,
  signal?: AbortSignal,
): Promise<ApiResponse<FacilityWithUtc>> => {
  const response = (await fetchApi({
    uri: `${apiVersion}/facility/all?page=${page}&pageSize=${pagesize}`,
    signal,
  })) as ApiResponse<Facility>;

  return {
    ...response,
    data: response.data.map(formatFacility),
  };
};

export const updateFacility = async (
  data: FacilityUpdateRequest,
): Promise<ApiSingleResponse<FacilityWithUtc>> => {
  const response = (await fetchApi({
    method: "PUT",
    uri: `${apiVersion}/facility`,
    payload: data,
  })) as ApiSingleResponse<Facility>;

  return {
    ...response,
    data: formatFacility(response.data),
  };
};

export const uploadFacilityLogo = async (
  facilityId: string,
  file: File,
  isStorage: boolean,
): Promise<FacilityWithUtc | null> => {
  const data = new FormData();
  data.append("formFiles", file, file.name);

  const response = (await upload(
    `${apiVersion}/facility/${facilityId}/logo/${isStorage}`,
    data,
  )) as ApiSingleResponse<Facility>;

  return formatFacility(response.data);
};

export const getAmenityList = async (
  page: number,
  pagesize: number,
): Promise<ApiResponse<FacilityAmenity>> =>
  (await fetchApi({
    uri: `${apiVersion}/facility/amenities?page=${page}&pageSize=${pagesize}`,
  })) as ApiResponse<FacilityAmenity>;

export const updateFacilityAmenity = async (
  facilityId: string,
  request: FacilityAmenityUpdateRequest,
): Promise<ApiSingleResponse<FacilityWithUtc>> => {
  const response = (await fetchApi({
    method: "PUT",
    uri: `${apiVersion}/facility/${facilityId}/amenity`,
    payload: request,
  })) as ApiSingleResponse<Facility>;

  return {
    ...response,
    data: formatFacility(response.data),
  };
};

export const updatePaymentInfo = async (
  facilityId?: string,
  request?: FacilityCreatePaymentInfoRequest,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<FacilityWithUtc>> => {
  const response = (await fetchApi({
    method: "PUT",
    uri: `${apiVersion}/facility/${facilityId}/paymentinfo`,
    payload: request,
    signal,
  })) as ApiSingleResponse<Facility>;

  return {
    ...response,
    data: formatFacility(response.data),
  };
};

export const getSportTypes = async (
  page: number,
  pagesize: number,
  signal?: AbortSignal,
): Promise<ApiResponse<SportType>> =>
  (await fetchApi({
    uri: `${apiVersion}/facility/sportTypes?page=${page}&pageSize=${pagesize}`,
    signal,
  })) as ApiResponse<SportType>;

export const updateBookableTypes = async (
  facilityId?: string,
  request?: FacilityUpdateBookableEntityTypes,
): Promise<ApiSingleResponse<FacilityWithUtc>> =>
  (await fetchApi({
    method: "PUT",
    uri: `${apiVersion}/facility/${facilityId}/entities/types`,
    payload: request,
  })) as ApiSingleResponse<FacilityWithUtc>;

export const getBookableTypesByFacilityId = async (
  facilityId?: string,
  signal?: AbortSignal,
): Promise<ApiResponse<BookableEntityType>> =>
  (await fetchApi({
    uri: `${apiVersion}/facility/${facilityId}/entities/types`,
    signal,
  })) as ApiResponse<BookableEntityType>;

export const getFacility = async (
  facilityId?: string,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<FacilityWithUtc>> => {
  try {
    const response = (await fetchApi({
      uri: `${apiVersion}/facility/${facilityId}`,
      signal,
    })) as ApiSingleResponse<Facility>;
    const { data, error, isSuccessful } = response;
    return {
      data: formatFacility(data),
      error,
      isSuccessful,
    } as ApiSingleResponse<FacilityWithUtc>;
  } catch (error) {
    console.log(error);
  }
};

export const getShortMessage = async (
  facilityId?: string,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<string>> =>
  (await fetchApi({
    uri: `${apiVersion}/facility/${facilityId}/shortMessage`,
    signal,
  })) as ApiSingleResponse<string>;

export const updateBookableEntity = async (
  facilityId?: string,
  request?: FacilityUpdateBookableEntities,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<FacilityWithUtc>> => {
  const response = (await fetchApi({
    method: "PUT",
    uri: `${apiVersion}/facility/${facilityId}/entities/bookable`,
    payload: request,
    signal,
  })) as ApiSingleResponse<Facility>;

  return {
    ...response,
    data: formatFacility(response.data),
  };
};

export const updateOpenHours = async (
  facilityId?: string,
  request?: FacilityUpdateOpenHoursRequest,
): Promise<ApiSingleResponse<FacilityWithUtc>> => {
  const response = (await fetchApi({
    method: "PUT",
    uri: `${apiVersion}/facility/${facilityId}/openhours`,
    payload: request,
  })) as ApiSingleResponse<Facility>;

  return {
    ...response,
    data: formatFacility(response.data),
  };
};

export const queryFacilities = async (
  searchKey: string,
): Promise<ApiResponse<FacilityWithUtc>> => {
  const response = (await fetchApi({
    method: "POST",
    uri: `${apiVersion}/facility/list`,
    payload: { searchKey },
  })) as ApiResponse<Facility>;

  return {
    ...response,
    data: response.data.map(formatFacility),
  };
};

export const createFacility = async (
  request: FacilityCreateRequest,
): Promise<ApiSingleResponse<FacilityWithUtc>> => {
  const response = (await fetchApi({
    method: "POST",
    uri: `${apiVersion}/facility`,
    payload: request,
  })) as ApiSingleResponse<Facility>;

  return {
    ...response,
    data: formatFacility(response.data),
  };
};

export const toggleFacilityIsActive = async (
  facilityId?: string,
): Promise<void> => {
  await fetchApi({
    method: "PUT",
    uri: `${apiVersion}/facility/${facilityId}/toggleisactive`,
  });
};

export const removeFacilityLogo = async (
  facilityId: string,
): Promise<ApiSingleResponse<FacilityWithUtc>> => {
  const response = (await fetchApi({
    method: "POST",
    uri: `${apiVersion}/facility/${facilityId}/remove`,
    payload: null,
  })) as ApiSingleResponse<Facility>;

  return {
    ...response,
    data: formatFacility(response.data),
  };
};

export const updateFacilityBookingRules = async (
  facilityId: FacilityWithUtc["id"],
  bookingRules: FacilityWithUtc["facilityBookingRules"],
): Promise<ApiSingleResponse<FacilityWithUtc>> => {
  const response = (await fetchApi({
    method: "PUT",
    uri: `${apiVersion}/facility/${facilityId}/bookingrules`,
    payload: bookingRules,
  })) as ApiSingleResponse<Facility>;

  return {
    ...response,
    data: formatFacility(response.data),
  };
};

export const getFacilityVendors = async (facilityId: FacilityWithUtc["id"]) => {
  const response = (await fetchApi({
    method: "GET",
    uri: `${apiVersion}/facility/${facilityId}/settings/facilityvendor`,
  })) as ApiResponse<FacilityVendor>;

  return response.data;
};

export const updateFacilityVendors = async (
  facilityId: FacilityWithUtc["id"],
  vendorObjects: FacilityVendor[],
) => {
  const response = (await fetchApi({
    method: "PUT",
    uri: `${apiVersion}/facility/${facilityId}/settings/facilityvendor`,
    payload: vendorObjects,
  })) as ApiResponse<FacilityVendor>;

  return response.data;
};

export const getFacilityQTCourts = async (
  facilityId: FacilityWithUtc["id"],
) => {
  const response = (await fetchApi({
    method: "GET",
    uri: `${apiVersion}/facility/${facilityId}/settings/qt/courts`,
  })) as ApiResponse<FacilityQTCourt>;

  return response.data;
};

export const updateFacilityQTCourts = async (
  facilityId: FacilityWithUtc["id"],
  qtCourts: FacilityQTCourt[],
) => {
  const response = (await fetchApi({
    method: "PUT",
    uri: `${apiVersion}/facility/${facilityId}/settings/qt/courts`,
    payload: qtCourts,
  })) as ApiResponse<FacilityQTCourt>;

  return response.data;
};

export const deleteCourt = async (
  facilityId: FacilityWithUtc["id"],
  courtId: string,
) =>
  (await fetchApi<ApiSingleResponse<boolean>>({
    method: "DELETE",
    uri: `${apiVersion}/facility/${facilityId}/deletecourt/${courtId}`,
  })) as ApiSingleResponse<boolean>;
