import { useMemo, useRef, useState, useTransition } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import {
  faArrowDownArrowUp,
  faBarsFilter,
  faCheck,
  faEllipsis,
  faSort,
  faSortAlphaAsc,
  faSortAlphaDesc,
  faSortAsc,
  faSortDesc,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Menu,
  MenuButton,
  MenuItem,
} from "@headlessui/react";
import {
  type CellContext,
  type HeaderContext,
  type Row,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { InputSwitch } from "primereact/inputswitch";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

import { BookingType } from "../../../../../models/BookingType";
import type { UnpaidObject } from "../../../../../models/unpaid";
import type { FacilityWithUtc } from "../../../../../modules/customer/models/Facility";

import {
  adminGetActivitesEventPath,
  adminGetActivitesSeriesPath,
  adminGetCalendarBookingDetailsPath,
  adminGetPlayerPath,
} from "../../../../../helpers/pathHelpers";

import { useToaster } from "../../../../../hooks/common/useToaster";
import { useSelectedFacility } from "../../../../../hooks/swr/useSelectedFacility";
import { useCurrencyFormat } from "../../../../../hooks/useCurrencyFormat";
import { useFacilitySportTypes } from "../../../../../hooks/useFacilitySportTypes";

import {
  getUnpaidPagedResult,
  getUnpaidTotalCount,
  markUnpaidObjectAsPaid,
} from "../../../../../services/facilityStatisticsService";

import { Button } from "../../../../../components/Button";
import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";
import {
  MenuItemButton,
  MenuItems,
} from "../../../../../components/DropdownMenu";
import {
  FilterListboxButton,
  FilterListboxOption,
  FilterListboxOptions,
} from "../../../../../components/FilterListbox";
import { SportTypeTranslatedName } from "../../../../../components/SportTypeTranslatedName";
import {
  Table,
  TableCurrencyCell,
  TableDateCell,
  TableDefaultRowComponent,
} from "../../../../../components/Table";
import { SelectInput } from "../../../../../components/inputs/SelectInput";

import { luxonDateFormat } from "../../../../../utils/dateFormats";

type Data = UnpaidObject;

const PAGE_SIZE = 10;

const columnHelper = createColumnHelper<Data>();

export const Unpaid = () => {
  const { formatMessage } = useIntl();
  const toaster = useToaster();
  const { selectedFacility } = useSelectedFacility();

  const [dateFilter, setDateFilter] = useState<"all" | "history" | "upcoming">(
    "history",
  );
  const [markAsPaidState, setMarkAsPaidState] = useState<{
    unpaidObject: UnpaidObject | undefined;
    state: "idle" | "loading" | "completed";
    sendEmail: boolean;
    markAllAsPaid: boolean;
  }>({
    unpaidObject: undefined,
    state: "idle",
    sendEmail: true,
    markAllAsPaid: false,
  });
  const markedAsPaid = useRef<Set<Data["id"]>>(new Set());

  const { data, isLoading, error, mutate } = useSWR(
    selectedFacility?.id
      ? ["unpaidPagedResult", selectedFacility.id, dateFilter]
      : undefined,
    ([, facilityId, dateFilter]) =>
      getUnpaidPagedResult(facilityId, {
        pageSize: 10_000,
        date: "descending",
        dateFilter,
      }).then(response => response.result),
    {
      fallbackData: [],
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 10_000,
      onError: () => {
        toaster.toastError.unknown();
      },
    },
  );

  const { data: totalCount, mutate: mutateTotalCount } = useSWRImmutable(
    selectedFacility ? ["unpaidTotalCount", selectedFacility.id] : undefined,
    ([, selectedFacilityId]) =>
      getUnpaidTotalCount(selectedFacilityId, { dateFilter: "all" }),
  );
  const { cf } = useCurrencyFormat(
    totalCount?.totalSumUnpaidDocument.currencyCode,
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor(row => row.user.name, {
        id: "name",
        sortDescFirst: false,
        sortingFn: "text",
        header: ({ column }) => (
          <button
            type="button"
            onClick={
              (column.getCanSort() && column.getToggleSortingHandler()) ||
              undefined
            }
            className="flex items-center gap-2"
          >
            <FormattedMessage id="common.name" />

            {column.getCanSort() && (
              <FontAwesomeIcon
                className={clsx(column.getIsSorted() && "text-primary")}
                icon={
                  column.getIsSorted()
                    ? column.getIsSorted() === "asc"
                      ? faSortAlphaAsc
                      : faSortAlphaDesc
                    : faArrowDownArrowUp
                }
              />
            )}
          </button>
        ),
        cell: props =>
          props.row.original.user.id ? (
            <Link to={adminGetPlayerPath(props.row.original.user.id)}>
              {props.getValue()}
            </Link>
          ) : (
            props.getValue()
          ),
      }),
      columnHelper.accessor(row => row.user.phone, {
        id: "phone",
        enableSorting: false,
        header: () => <FormattedMessage id="common.phone" />,
      }),
      columnHelper.accessor(row => row.user.email, {
        id: "email",
        enableSorting: false,
        header: () => <FormattedMessage id="common.email" />,
      }),
      columnHelper.accessor("sportType", {
        enableColumnFilter: true,
        filterFn: "weakEquals",
        header: props => (
          <SportTypeHeader {...props} facilityId={selectedFacility?.id} />
        ),
      }),
      columnHelper.accessor("bookingType", {
        header: ActivityNameHeader,
        cell: ActivityNameCell,
      }),
      columnHelper.accessor("activityDate", {
        header: ({ column }) => (
          <button
            type="button"
            className="flex items-center gap-2"
            onClick={column.getToggleSortingHandler()}
          >
            <FormattedMessage id="common.date" />
            <FontAwesomeIcon
              className={clsx(column.getIsSorted() && "text-primary")}
              icon={
                column.getIsSorted()
                  ? column.getIsSorted() === "asc"
                    ? faSortAsc
                    : faSortDesc
                  : faSort
              }
            />
          </button>
        ),
        cell: props => {
          const value = props.getValue();

          if (!value || !selectedFacility?.id) {
            return null;
          }

          return (
            <TableDateCell
              value={value}
              dateFormat={luxonDateFormat}
              facilityId={selectedFacility.id}
            />
          );
        },
      }),
      columnHelper.accessor(row => row.amount.valueInclTax, {
        id: "amount",
        enableSorting: false,
        header: () => (
          <div className="text-right">
            <FormattedMessage id="common.amount.price" />
          </div>
        ),
        cell: props => (
          <TableCurrencyCell
            value={props.getValue()}
            currency={selectedFacility?.localization?.currencyCode}
            options={{ minimumFractionDigits: 2 }}
          />
        ),
      }),
      columnHelper.accessor(row => row.amount.tax, {
        id: "tax",
        enableSorting: false,
        header: () => (
          <div className="text-right">
            <FormattedMessage id="receipts.tax.message" />
          </div>
        ),
        cell: props => (
          <TableCurrencyCell
            value={props.getValue()}
            currency={selectedFacility?.localization?.currencyCode}
            options={{ minimumFractionDigits: 2 }}
          />
        ),
      }),
      columnHelper.display({
        id: "paymentStatus",
        header: () => <FormattedMessage id="common.payment.status" />,
        cell: props => (
          <div className="flex items-center gap-12">
            <div className="flex items-center gap-2">
              <span className="block size-2 rounded-full bg-red-600" />
              <FormattedMessage id="bookings.not-payed" />
            </div>

            <Menu>
              <MenuButton className="flex items-center">
                <FontAwesomeIcon icon={faEllipsis} />
              </MenuButton>

              <MenuItems anchor="left">
                <MenuItem disabled={!props.row.original.allowMarkAsPaid}>
                  <MenuItemButton
                    onClick={() =>
                      setMarkAsPaidState(v => ({
                        ...v,
                        state: "idle",
                        unpaidObject: props.row.original,
                        markAllAsPaid: false,
                      }))
                    }
                  >
                    <FormattedMessage id="common.mark-as-paid" />
                  </MenuItemButton>
                </MenuItem>

                {props.row.original.bookingType === BookingType.Recurring && (
                  <MenuItem disabled={!props.row.original.allowMarkAsPaid}>
                    <MenuItemButton
                      onClick={() =>
                        setMarkAsPaidState(v => ({
                          ...v,
                          state: "idle",
                          unpaidObject: props.row.original,
                          markAllAsPaid: true,
                        }))
                      }
                    >
                      <FormattedMessage id="common.mark-all-as-paid" />
                    </MenuItemButton>
                  </MenuItem>
                )}
              </MenuItems>
            </Menu>
          </div>
        ),
      }),
    ],
    [selectedFacility?.id, selectedFacility?.localization?.currencyCode],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      sorting: [
        {
          id: "activityDate",
          desc: true,
        },
      ],
      pagination: {
        pageSize: PAGE_SIZE,
      },
    },
  });

  const [isPending, startTransition] = useTransition();

  const CustomRowComponent = ({ row }: { row: Row<Data> }) => {
    if (markedAsPaid.current.has(row.original.id)) {
      return (
        <tr className="[&>td]:border-l-2 [&>td]:border-l-transparent first:[&>td]:border-0 [&>td]:even:border-l-purewhite [&>td]:even:bg-white">
          <td className="p-4 text-center font-bold" colSpan={100}>
            <FormattedMessage id="admin.marked-as-paid" />
          </td>
        </tr>
      );
    }

    return <TableDefaultRowComponent row={row} />;
  };

  const filteredAmount = table.getFilteredRowModel().rows.reduce((acc, row) => {
    if (!row.original.amount) {
      return acc;
    }

    if (markedAsPaid.current.has(row.original.id)) {
      return acc;
    }

    return acc + row.original.amount.valueInclTax;
  }, 0);

  return (
    <>
      <div>
        <div className="flex flex-col justify-between gap-4 sm:flex-row">
          <div className="space-y-2">
            <h3>
              <FormattedMessage id="admin.facility.settings.economy.unpaid.title" />{" "}
              <span className="font-normal">
                ({isLoading ? "..." : data.length})
              </span>
            </h3>
            <p className="">
              <b>
                <FormattedMessage id="admin.facility.settings.economy.unpaid.total-expected-revenue" />
                :
              </b>{" "}
              {typeof totalCount !== "undefined" ? (
                <>
                  {cf(totalCount?.totalSumUnpaidDocument.valueInclTax)} (
                  <FormattedMessage id="common.incl-vat" />)
                </>
              ) : (
                "..."
              )}
            </p>
            <p className="text-primary">
              <b>
                <FormattedMessage id="admin.facility.settings.economy.unpaid.total-of-filtered-values" />
                : {!isLoading ? cf(filteredAmount) : "..."}
              </b>
            </p>
          </div>

          <SelectInput
            disabled={isLoading || isPending}
            className="text-sm"
            options={[
              {
                label: formatMessage({
                  id: "common.all",
                }),
                value: "all",
              },
              {
                label: formatMessage({ id: "common.upcoming" }),
                value: "upcoming",
              },
              {
                label: formatMessage({ id: "common.history" }),
                value: "history",
              },
            ]}
            value={dateFilter}
            onChange={e => {
              startTransition(() => {
                table.reset();
                setDateFilter(e.value);
              });
            }}
          />
        </div>

        <div className="mt-4 text-sm sm:mt-8">
          <Table
            table={table}
            isLoading={isLoading}
            RowComponent={CustomRowComponent}
          />

          {!isLoading && !error && table.getRowCount() !== 0 && (
            <div className="mt-16 flex justify-center">
              <Button
                size="small"
                type="primary"
                disabled={!table.getCanNextPage()}
                onClick={() => table.setPageSize(v => v + PAGE_SIZE)}
              >
                <FormattedMessage id="common.show.more" />
              </Button>
            </div>
          )}
        </div>
      </div>

      {markAsPaidState.state !== "completed" &&
        markAsPaidState.unpaidObject && (
          <ConfirmationDialog
            visible
            title={formatMessage({
              id: markAsPaidState.markAllAsPaid
                ? "admin-edit-booking-form.mark-all-recurring-bookings-as-paid.confirmation.title"
                : "admin.markAsPaid.confirm.title",
            })}
            text={
              !markAsPaidState.markAllAsPaid && (
                <FormattedMessage
                  id="admin.markAsPaid.confirm.description"
                  values={{
                    Bold: chunk => <b>{chunk}</b>,
                    name: markAsPaidState.unpaidObject.user.name,
                  }}
                />
              )
            }
            loading={markAsPaidState.state === "loading"}
            onHide={() =>
              setMarkAsPaidState(v => ({
                ...v,
                state: "idle",
                unpaidObject: undefined,
              }))
            }
            onCancel={() =>
              setMarkAsPaidState(v => ({
                ...v,
                state: "idle",
                unpaidObject: undefined,
              }))
            }
            onSubmit={async () => {
              if (!markAsPaidState.unpaidObject || !selectedFacility) {
                return;
              }

              setMarkAsPaidState(v => ({
                ...v,
                state: "loading",
              }));

              try {
                await markUnpaidObjectAsPaid(
                  selectedFacility.id,
                  markAsPaidState.unpaidObject.id,
                  {
                    sendConfirmationEmailToUser: markAsPaidState.sendEmail,
                    markAllAsPaid: markAsPaidState.markAllAsPaid,
                  },
                );

                if (markAsPaidState.markAllAsPaid) {
                  setTimeout(() => {
                    // setTimeout? Really? yes. Backend replies when payment is done.
                    // We need to wait for series status updates, and since backend dont wait we do.

                    toaster.toastSuccess.message(
                      "admin-edit-booking-form.all-recurring-bookings-marked-as-paid.toast.success",
                    );
                    mutateTotalCount(undefined);
                    mutate(undefined);
                    setMarkAsPaidState(v => ({
                      ...v,
                      state: "completed",
                      sendEmail: true,
                    }));
                  }, 3000);
                } else {
                  toaster.toastSuccess.message("admin.marked-as-paid");
                  setMarkAsPaidState(v => ({
                    ...v,
                    state: "completed",
                    sendEmail: true,
                  }));
                  markedAsPaid.current.add(markAsPaidState.unpaidObject.id);
                  mutateTotalCount(undefined);
                }
              } catch (e) {
                console.error(e);
                toaster.toastError.unknown();

                setMarkAsPaidState(v => ({
                  ...v,
                  state: "idle",
                }));
              }
            }}
          >
            <div className="mt-5 flex items-center justify-center gap-5">
              <label htmlFor="sendEmail">
                <FormattedMessage id="admin.booking.send-confirmation-email" />
              </label>
              <InputSwitch
                inputId="sendEmail"
                checked={markAsPaidState.sendEmail}
                onChange={e =>
                  setMarkAsPaidState(v => ({
                    ...v,
                    sendEmail: e.value,
                  }))
                }
              />
            </div>
          </ConfirmationDialog>
        )}
    </>
  );
};

const SportTypeHeader = ({
  column,
  facilityId,
}: HeaderContext<Data, Data["sportType"]> & {
  facilityId: FacilityWithUtc["id"] | undefined;
}) => {
  const { sportTypes } = useFacilitySportTypes(facilityId);

  return sportTypes ? (
    <Listbox
      value={column.getFilterValue() ?? ""}
      onChange={val => {
        column.setFilterValue((currentValue: unknown) =>
          currentValue !== val ? val : "",
        );
      }}
    >
      <ListboxButton
        as={FilterListboxButton}
        data-active={column.getIsFiltered() ? "" : undefined}
      >
        <FormattedMessage id="common.sport-type" />

        <FontAwesomeIcon icon={faBarsFilter} />
      </ListboxButton>

      <ListboxOptions as={FilterListboxOptions} anchor="bottom">
        <ListboxOption value="" className="hidden" />

        {sportTypes.map(sportType => {
          if (!sportType.name) {
            return null;
          }

          return (
            <ListboxOption
              as={FilterListboxOption}
              value={sportType.name}
              key={sportType.id}
            >
              <SportTypeTranslatedName sportType={sportType.name} />

              {column.getFilterValue() === sportType.name && (
                <FontAwesomeIcon className="text-primary" icon={faCheck} />
              )}
            </ListboxOption>
          );
        })}
      </ListboxOptions>
    </Listbox>
  ) : (
    <FormattedMessage id="common.sport-type" />
  );
};

const ActivityNameHeader = ({
  column,
}: HeaderContext<Data, Data["activityName"]>) => {
  const map = {
    [BookingType.Event]: "common.event",
    [BookingType.Series]: "common.serie",
    [BookingType.Regular]: "common.booking",
    [BookingType.Open]: "common.open-booking",
    [BookingType.Recurring]: "common.recurring",
  };

  return (
    <Listbox
      value={column.getFilterValue() ?? ""}
      onChange={val => {
        column.setFilterValue((currentValue: unknown) =>
          currentValue !== val ? val : "",
        );
      }}
    >
      <ListboxButton
        as={FilterListboxButton}
        data-active={column.getIsFiltered() ? "" : undefined}
      >
        <FormattedMessage id="common.activity" />

        <FontAwesomeIcon icon={faBarsFilter} />
      </ListboxButton>

      <ListboxOptions as={FilterListboxOptions} anchor="bottom">
        <ListboxOption value="" className="hidden" />

        {Object.entries(map).map(([bookingType, translationId]) => (
          <ListboxOption
            as={FilterListboxOption}
            value={bookingType}
            key={bookingType}
          >
            <FormattedMessage id={translationId} />

            {column.getFilterValue() === bookingType && (
              <FontAwesomeIcon className="text-primary" icon={faCheck} />
            )}
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
};

const ActivityNameCell = (props: CellContext<Data, Data["bookingType"]>) => {
  let url = "";

  if (props.row.original.bookingType === BookingType.Event) {
    url = adminGetActivitesEventPath(props.row.original.sourceId);
  } else if (props.row.original.bookingType === BookingType.Series) {
    url = adminGetActivitesSeriesPath(props.row.original.sourceId);
  } else if (
    [
      BookingType.NotBookable,
      BookingType.Regular,
      BookingType.Open,
      BookingType.Recurring,
    ].includes(props.row.original.bookingType)
  ) {
    url = adminGetCalendarBookingDetailsPath(props.row.original.sourceId);
  }

  return url ? (
    <Link to={url}>{props.row.original.activityName}</Link>
  ) : (
    props.row.original.activityName
  );
};
