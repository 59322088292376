import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  faCalendarPen,
  faCopy,
  faEllipsis,
  faMoneyBill,
  faMoneyBillTransfer,
  faPen,
  faTrash,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuButton, MenuItem } from "@headlessui/react";
import { DateTime } from "luxon";
import { Tooltip } from "primereact/tooltip";

import { BookingType, type IBooking } from "../../../../models/Booking";

import { useToaster } from "../../../../../../hooks/common/useToaster";
import { useIsSuperAdmin } from "../../../../../../hooks/permissions";
import { useOpenBooking } from "../../../../../../hooks/swr/facilityOpenBookings/useOpenBooking";
import { useIsFeatureFlagEnabled } from "../../../../../../hooks/swr/mySettings/useFeatureFlags";
import { useBooking } from "../../../../../../hooks/swr/useBooking";

import { cancelOpenBooking } from "../../../../../../services/facilityOpenBookingsService";
import {
  markAllRecurringBookingsAsPaid,
  unBookPlayerAdmin,
} from "../../../../services/Booking";

import { ConfirmationDialog } from "../../../../../../components/ConfirmationDialog";
import { Dialog } from "../../../../../../components/Dialog";
import {
  MenuItemButton,
  MenuItems,
} from "../../../../../../components/DropdownMenu";
import { ProgressSpinner } from "../../../../../../components/ProgressSpinner";
import { OpenBookingEdit } from "./components/OpenBookingEdit";
import { Payments } from "./components/Payments";

import { bookingTypeBorderLeftColor } from "../../utils";
import { useAdminCalendarContext } from "../AdminCalendarContext";
import { AdminCalendarBookingDetails } from "./AdminCalendarBookingDetails";
import { AdminCalendarDetailsHeader } from "./AdminCalendarDetailsHeader";
import { AdminCalendarEventDetails } from "./AdminCalendarEventDetails";
import { AdminCalendarSeriesDetails } from "./AdminCalendarSeriesDetails";

interface Props {
  bookingId: IBooking["id"];
  onSubmitted: (refreshView?: boolean, isEditDialogOpen?: boolean) => void;
}

export const AdminCalendarDetails = ({ bookingId, onSubmitted }: Props) => {
  const { formatMessage } = useIntl();

  const toaster = useToaster();
  const {
    state: { bookingBeingRescheduled },
    dispatch,
  } = useAdminCalendarContext();
  const isSuperAdmin = useIsSuperAdmin();

  const [cancelBookingConfirmation, setCancelBookingConfirmation] = useState<{
    show?: boolean;
    loading?: boolean;
  }>({});
  const [markAllAsPaidConfirmation, setMarkAllAsPaidConfirmation] = useState<{
    show?: boolean;
    loading?: boolean;
  }>({});
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [isOpenBookingEditMode, setIsOpenBookingEditMode] = useState(false);

  const { booking, isLoading, mutate } = useBooking(
    bookingId,
    "participants,payments,individualprice",
    { refreshInterval: 60_000 },
  );

  // Only here to prime the cache for AdminCalendarBookingDetails.tsx
  const {
    openBooking,
    isLoading: isOpenBookingLoading,
    mutate: mutateOpenBooking,
  } = useOpenBooking(
    booking?.type === BookingType.Open ? booking.facilityId : undefined,
    booking?.externalServiceId,
  );

  const isRefundFeatureFlagEnabled = useIsFeatureFlagEnabled(
    "Refund.Booking.Admin",
  );

  // Close the details view and refresh the calendar view when no booking is found.
  useEffect(() => {
    if (isLoading || isOpenBookingLoading) {
      return;
    }

    if (!booking || (booking?.type === BookingType.Open && !openBooking)) {
      toaster.toastError.unknown();
      onSubmitted(true, false);
    }
  }, [
    booking,
    isLoading,
    isOpenBookingLoading,
    onSubmitted,
    openBooking,
    toaster.toastError,
  ]);

  // Close the edit mode when booking is changed
  useEffect(() => {
    setIsOpenBookingEditMode(false);
  }, [bookingId]);

  if (isLoading || isOpenBookingLoading) {
    return (
      <div key="spinner-wrapper" className="py-px">
        <ProgressSpinner />
      </div>
    );
  }

  if (!booking || (booking?.type === BookingType.Open && !openBooking)) {
    return null;
  }

  const isBookingEditable = booking.startTime > DateTime.now();

  const isBookingReschedulable =
    isBookingEditable &&
    [
      BookingType.Regular,
      BookingType.Recurring,
      BookingType.Series,
      BookingType.NotBookable,
      BookingType.Open,
    ].includes(booking.type);

  const isBookingCancelable =
    isBookingEditable &&
    [
      BookingType.Regular,
      BookingType.NotBookable,
      BookingType.Recurring,
      BookingType.Open,
    ].includes(booking.type);

  const isPaymentsModalViewable =
    booking.participants.length > 0 &&
    [BookingType.Regular, BookingType.Recurring, BookingType.Open].includes(
      booking.type,
    ) &&
    (isRefundFeatureFlagEnabled || isSuperAdmin);

  const isOpenBookingEditable =
    booking.type === BookingType.Open && booking?.startTime > DateTime.now();

  const isOpenBooking = booking.type === BookingType.Open && openBooking;

  const showMenu =
    isBookingReschedulable ||
    isBookingCancelable ||
    booking.type === BookingType.Recurring ||
    isPaymentsModalViewable ||
    isOpenBookingEditable ||
    isOpenBooking;

  return (
    <>
      <div
        className={`rounded-lg border border-l-8 border-gray-150 p-6 text-sm sm:p-8 ${bookingTypeBorderLeftColor(booking.type)}`}
      >
        <div className="flex h-px items-center justify-end gap-3">
          {showMenu && (
            <Menu>
              <MenuButton className="text-2xl">
                <FontAwesomeIcon icon={faEllipsis} />
              </MenuButton>

              <MenuItems className="whitespace-nowrap">
                {isBookingReschedulable && (
                  <MenuItem disabled={!!bookingBeingRescheduled}>
                    <MenuItemButton
                      onClick={() => {
                        dispatch({
                          type: "SET_BOOKING_BEING_RESCHEDULED",
                          payload: booking,
                        });
                        onSubmitted(false, false);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCalendarPen}
                        className="mr-2 inline-block"
                      />
                      <FormattedMessage id="common.reschedule" />
                      ...
                    </MenuItemButton>
                  </MenuItem>
                )}

                {isPaymentsModalViewable && (
                  <MenuItem>
                    <MenuItemButton onClick={() => setShowPaymentsModal(true)}>
                      <FontAwesomeIcon
                        icon={faMoneyBillTransfer}
                        className="mr-2 inline-block"
                      />
                      <FormattedMessage id="common.payments" />
                    </MenuItemButton>
                  </MenuItem>
                )}

                {booking.type === BookingType.Recurring && (
                  <MenuItem>
                    <MenuItemButton
                      onClick={() =>
                        setMarkAllAsPaidConfirmation({ show: true })
                      }
                    >
                      <FontAwesomeIcon
                        icon={faMoneyBill}
                        className="mr-2 inline-block"
                      />
                      <FormattedMessage id="common.mark-all-as-paid" />
                      ...
                    </MenuItemButton>
                  </MenuItem>
                )}

                {isOpenBookingEditable && (
                  <MenuItem>
                    <MenuItemButton
                      onClick={() => {
                        setIsOpenBookingEditMode(v => !v);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPen}
                        className="mr-2 inline-block"
                      />
                      {isOpenBookingEditMode ? (
                        <FormattedMessage id="common.cancel" />
                      ) : (
                        <FormattedMessage id="common.edit" />
                      )}
                    </MenuItemButton>
                  </MenuItem>
                )}

                {isOpenBooking && openBooking && (
                  <MenuItem>
                    <MenuItemButton
                      onClick={() => {
                        const url = new URL(
                          process.env.REACT_APP_WEBSITE_URL ?? "",
                        );
                        url.pathname = `/open-booking/${openBooking.id}`;

                        navigator.clipboard
                          .writeText(url.toString())
                          .then(() => {
                            toaster.toastSuccess.message("common.link.copied");
                          });
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCopy}
                        className="mr-2 inline-block"
                      />
                      <FormattedMessage id="common.copy-share-link" />
                    </MenuItemButton>
                  </MenuItem>
                )}

                {isBookingCancelable && (
                  <MenuItem>
                    <MenuItemButton
                      className="text-red-600"
                      onClick={() =>
                        setCancelBookingConfirmation({ show: true })
                      }
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="mr-2 inline-block"
                      />
                      <FormattedMessage id="common.unbook" />
                      ...
                    </MenuItemButton>
                  </MenuItem>
                )}
              </MenuItems>
            </Menu>
          )}

          <button
            className="max-sm:hidden"
            onClick={() => onSubmitted(false, false)}
          >
            <FontAwesomeIcon icon={faXmark} className="text-md" />
          </button>
        </div>

        <AdminCalendarDetailsHeader booking={booking} />

        {isOpenBookingEditMode && openBooking ? (
          <OpenBookingEdit
            booking={booking}
            openBooking={openBooking}
            mutateOpenBooking={mutateOpenBooking}
            exitEditMode={() => setIsOpenBookingEditMode(false)}
          />
        ) : [
            BookingType.Regular,
            BookingType.NotBookable,
            BookingType.Recurring,
            BookingType.Open,
          ].includes(booking.type) ? (
          <AdminCalendarBookingDetails
            booking={booking}
            onSubmitted={onSubmitted}
            mutateBooking={mutate}
          />
        ) : booking.type === BookingType.Series ? (
          <AdminCalendarSeriesDetails
            booking={booking}
            onSubmitted={onSubmitted}
            mutateBooking={mutate}
          />
        ) : booking.type === BookingType.Event ? (
          <AdminCalendarEventDetails booking={booking} />
        ) : null}

        <Tooltip target=".admin-calendar-details" />
      </div>

      {cancelBookingConfirmation.show && (
        <ConfirmationDialog
          visible
          title={formatMessage({
            id: "common.cancel-the-booking?",
          })}
          loading={cancelBookingConfirmation.loading}
          confirmButtonType="danger"
          onHide={() => setCancelBookingConfirmation({})}
          onCancel={() => setCancelBookingConfirmation({})}
          onSubmit={async () => {
            setCancelBookingConfirmation(v => ({ ...v, loading: true }));

            try {
              if (
                booking.type === BookingType.Open &&
                booking.externalServiceId
              ) {
                await cancelOpenBooking(
                  booking.facilityId,
                  booking.externalServiceId,
                );
              } else {
                await unBookPlayerAdmin(bookingId, {
                  alwaysRefund: true,
                  userIds: [],
                  refundAll: false,
                });
              }

              toaster.toastSuccess.message("toast.success.cancel-done");
              mutate(undefined, true);
              onSubmitted(true, false);
            } catch {
              toaster.toastError.unknown();
              setCancelBookingConfirmation(v => ({ ...v, loading: false }));
            }
          }}
        />
      )}

      {markAllAsPaidConfirmation.show && (
        <ConfirmationDialog
          visible
          confirmButtonType="danger"
          loading={markAllAsPaidConfirmation.loading}
          title={formatMessage({
            id: "admin-edit-booking-form.mark-all-recurring-bookings-as-paid.confirmation.title",
          })}
          onCancel={() => setMarkAllAsPaidConfirmation({})}
          onHide={() => setMarkAllAsPaidConfirmation({})}
          onSubmit={async () => {
            setMarkAllAsPaidConfirmation(v => ({ ...v, loading: true }));

            try {
              await markAllRecurringBookingsAsPaid(booking.id);
              setTimeout(() => {
                // setTimeout? Really? yes. Backend replies when payment is done.
                // We need to wait for series status updates, and since backend dont wait we do.

                toaster.toastSuccess.message(
                  "admin-edit-booking-form.all-recurring-bookings-marked-as-paid.toast.success",
                );
                mutate();
                setMarkAllAsPaidConfirmation({});
                onSubmitted(true, true);
              }, 3000);
            } catch {
              toaster.toastError.unknown();
              setMarkAllAsPaidConfirmation(v => ({ ...v, loading: false }));
            }
          }}
        />
      )}

      {showPaymentsModal && (
        <Dialog
          onHide={() => setShowPaymentsModal(false)}
          visible
          className="!max-w-4xl"
        >
          <Payments booking={booking} />
        </Dialog>
      )}
    </>
  );
};
