import { forwardRef } from "react";

import clsx from "clsx";

export const FilterListboxButton = forwardRef<
  HTMLButtonElement,
  React.ComponentProps<"button">
>(({ className, ...props }, ref) => (
  <button
    ref={ref}
    {...props}
    className={clsx(
      "group flex items-center gap-2 [&>svg]:rounded [&>svg]:p-1 [&>svg]:transition-colors [&>svg]:hover:bg-gray-50 [&>svg]:hover:text-primary [&>svg]:data-[active]:bg-gray-50 [&>svg]:data-[active]:text-primary",
      className,
    )}
  />
));
FilterListboxButton.displayName = "FilterListboxButton";

export const FilterListboxOptions = forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    {...props}
    className={clsx(
      "z-40 flex w-44 flex-col rounded-lg border border-gray-200 bg-white py-4 text-sm font-semibold shadow-md [--anchor-gap:4px]",
      className,
    )}
  />
));
FilterListboxOptions.displayName = "FilterListboxOptions";

export const FilterListboxOption = forwardRef<
  HTMLButtonElement,
  React.ComponentProps<"button">
>(({ className, type = "button", ...props }, ref) => (
  <button
    ref={ref}
    {...props}
    type={type}
    className={clsx(
      "group flex cursor-pointer items-center justify-between border-transparent px-4 py-2 text-left text-sm transition-colors hover:bg-gray-150 data-[disabled]:cursor-not-allowed data-[disabled]:select-none data-[disabled]:!bg-transparent data-[focus]:bg-blue-50 data-[selected]:bg-blue-50 data-[disabled]:opacity-25",
      className,
    )}
  />
));
FilterListboxOption.displayName = "FilterListboxOption";
