import { DateOnly } from "../models/DateOnly";
import type {
  UnpaidObject,
  UnpaidObjectApiResponse,
  UnpaidPagedResult,
  UnpaidPagedResultApiResponse,
  UnpaidTotalCount,
} from "../models/unpaid";
import type { FacilityWithUtc } from "../modules/customer/models/Facility";

import { get, post } from "./apiClient";

const API_VERSION = "v1";

const getEndpointBase = (facilityId: FacilityWithUtc["id"]) =>
  `/${API_VERSION}/facilities/${facilityId}/statistics`;

export const getUnpaidPagedResult = async (
  facilityId: FacilityWithUtc["id"],
  urlSearchParams?: {
    fromId?: string;
    pageSize?: number;
    dateFilter?: "all" | "history" | "upcoming";
    name?: "ascending" | "descending";
    date?: "ascending" | "descending";
  },
): Promise<UnpaidPagedResult> =>
  get<UnpaidPagedResultApiResponse>({
    endpoint: `${getEndpointBase(facilityId)}/unpaid`,
    urlSearchParams,
  }).then(response => ({
    ...response,
    result: response.result.map(formatUnpaidObject),
  }));

export const getUnpaidTotalCount = async (
  facilityId: FacilityWithUtc["id"],
  urlSearchParams?: {
    dateFilter?: "all" | "history" | "upcoming";
  },
) =>
  get<UnpaidTotalCount>({
    endpoint: `${getEndpointBase(facilityId)}/unpaid/total-count`,
    urlSearchParams,
  });

export const markUnpaidObjectAsPaid = async (
  facilityId: FacilityWithUtc["id"],
  unpaidObjectId: UnpaidObject["id"],
  body: {
    sendConfirmationEmailToUser: boolean;
    markAllAsPaid: boolean;
  },
) =>
  post({
    endpoint: `${getEndpointBase(facilityId)}/unpaid/${unpaidObjectId}/mark-as-paid`,
    parseDataAs: "none",
    body,
  });

const formatUnpaidObject = (
  unpaidObject: UnpaidObjectApiResponse,
): UnpaidObject => ({
  ...unpaidObject,
  activityDate: unpaidObject.activityDate
    ? DateOnly.fromISODate(unpaidObject.activityDate)
    : null,
});
