import { forwardRef } from "react";

import type { MenuItemsProps } from "@headlessui/react";
import { MenuItems as DefaultMenuItems } from "@headlessui/react";
import clsx from "clsx";

export const MenuItems = ({
  className,
  anchor = "bottom",
  ...props
}: MenuItemsProps) => (
  <DefaultMenuItems
    {...props}
    anchor={anchor}
    className={clsx(
      "z-40 flex flex-col rounded-lg border border-gray-200 bg-white p-1.5 text-sm shadow-md outline-none [--anchor-gap:4px]",
      className,
    )}
  />
);

export const MenuItemButton = forwardRef<
  HTMLButtonElement,
  React.ComponentProps<"button">
>(
  (
    { className, type = "button", ...props }: React.ComponentProps<"button">,
    ref,
  ) => (
    <button
      ref={ref}
      {...props}
      type={type}
      className={clsx(
        "cursor-pointer rounded-lg border-transparent px-3 py-2 text-left text-sm transition-colors hover:bg-gray-150 data-[disabled]:cursor-not-allowed data-[disabled]:select-none data-[disabled]:!bg-transparent data-[focus]:bg-gray-150 data-[disabled]:opacity-25",
        className,
      )}
    />
  ),
);
MenuItemButton.displayName = "MenuItemButton";
