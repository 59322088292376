export enum BookingType {
  NotBookable = "NotBookable",
  Regular = "Regular",
  Subscription = "Subscription",
  Series = "Series",
  Activity = "Activity",
  Training = "Training",
  Recurring = "Recurring",
  Undefined = "Undefined",
  Event = "GeneralActivity",
  Open = "OpenBooking",
}
