import { DateTime } from "luxon";

import { ApiResponse, ApiSingleResponse } from "../../../models/common";
import type { FacilityWithUtc } from "../../customer/models/Facility";
import {
  IAdminCancelBookingRequest,
  IBooking,
  ICreateBookingFormValues,
  ICreateRecurringBookingFormValues,
  IRescheduleBookingFormValues,
  IUpdateBookingCommentFormValues,
} from "../models/Booking";
import { Payment, PaymentInfo } from "../models/Payment";
import { IBookingWithoutUTC, IRescheduleBooking } from "../models/api/booking";

import { dateTimeToUtcIsoSanitizedToMinutes } from "../../../helpers/dateTime";
import { formatBooking, formatBookings } from "../helpers/typesMapping";

import { fetchApi } from "../../../services/legacyApiClient";

import { createUrlSearchParams } from "../../../utils/createUrlSearchParams";

const apiVersion = "checkout/v3";

export const getBooking = async (
  bookingId: string,
  include?: string,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<IBooking>> => {
  try {
    const response = await fetchApi<ApiSingleResponse<IBookingWithoutUTC>>({
      uri: `${apiVersion}/bookings/${bookingId}?include=${include}&active=true`,
      signal,
    });

    const { data, error, isSuccessful } =
      response as ApiSingleResponse<IBookingWithoutUTC>;

    const responseModel: IBooking = formatBooking(data);

    return {
      data: responseModel,
      error,
      isSuccessful,
    };
  } catch (err) {
    return { data: null, error: err, isSuccessful: false };
  }
};

export const getAllBookingsOnExternalId = async (
  externalId: string,
  include?: string,
): Promise<ApiResponse<IBooking>> => {
  try {
    const response = await fetchApi<ApiResponse<IBookingWithoutUTC>>({
      uri: `${apiVersion}/bookings/external/${externalId}?include=${include}`,
    });
    const { data, count } = response as ApiResponse<IBookingWithoutUTC>;
    const responseModel: IBooking[] = formatBookings(data);
    return {
      data: responseModel,
      count,
    };
  } catch (err) {
    console.log(err);
  }
};

export const createBooking = async (
  payload: ICreateBookingFormValues,
  facilityId: string,
): Promise<ApiSingleResponse<IBooking>> => {
  try {
    const startTime = dateTimeToUtcIsoSanitizedToMinutes(payload.startTime);
    const endTime = dateTimeToUtcIsoSanitizedToMinutes(payload.endTime);

    const response = await fetchApi<ApiSingleResponse<IBookingWithoutUTC>>({
      method: "POST",
      uri: `${apiVersion}/bookings/${facilityId}`,
      payload: { ...payload, startTime, endTime },
    });
    const { data, error, isSuccessful } =
      response as ApiSingleResponse<IBookingWithoutUTC>;
    const responseModel: IBooking = formatBooking(data);
    return {
      data: responseModel,
      error,
      isSuccessful,
    };
  } catch (err) {
    console.log(err);
    return { data: null, error: err, isSuccessful: false };
  }
};

export const cancelBooking = async (
  bookingId: string,
  signal?: AbortSignal,
): Promise<boolean> =>
  await fetchApi<boolean>({
    method: "POST",
    uri: `${apiVersion}/bookings/${bookingId}/cancel`,
    signal,
  });

export const rescheduleBooking = async (
  bookingId: string,
  data: IRescheduleBookingFormValues,
  sendEmail?: boolean, // Defaults to true on the backend
): Promise<boolean> => {
  try {
    const payload: IRescheduleBooking = {
      ...data,
      startTime: data.startTime.toISO(),
      endTime: data.endTime.toISO(),
    };
    const params = createUrlSearchParams({
      sendEmailToParticipants: sendEmail,
    });

    const response = await fetchApi<boolean>({
      method: "PUT",
      uri: `${apiVersion}/bookings/${bookingId}/reschedule?${params.toString()}`,
      payload: payload,
    });
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const payForBooking = async (
  bookingId: string,
  paymentMethod: string,
  data?: PaymentInfo,
  signal?: AbortSignal,
): Promise<Payment | null> => {
  try {
    const response = (await fetchApi<ApiSingleResponse<Payment>>({
      method: "POST",
      uri: `${apiVersion}/bookings/${bookingId}/pay/${paymentMethod}`,
      payload: data,
      signal,
    })) as ApiSingleResponse<Payment>;
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const markBookingAsPaid = async (
  bookingId: string,
  userId: string,
  sendConfirmationEmailToUser: boolean,
  signal?: AbortSignal,
): Promise<boolean | null> => {
  try {
    const response = (await fetchApi<ApiSingleResponse<boolean>>({
      method: "POST",
      uri: `${apiVersion}/bookings/admin/markaspaid/${bookingId}/${userId}?sendConfirmationEmailToUser=${sendConfirmationEmailToUser}`,
      signal,
    })) as ApiSingleResponse<boolean>;
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const markAllRecurringBookingsAsPaid = async (
  bookingId: IBooking["id"],
) => {
  return fetchApi<boolean>({
    method: "POST",
    uri: `${apiVersion}/bookings/admin/markaspaid/recurring/${bookingId}`,
  });
};

export const createRecurringBookings = async (
  data: Omit<ICreateRecurringBookingFormValues, "endTime" | "startTime"> & {
    endTime: DateTime;
    startTime: DateTime;
  },
  facilityId: string,
): Promise<ApiResponse<string>> => {
  const payload = {
    ...data,
    startTime: data.startTime.toUTC().toISO(),
    endTime: data.endTime.toUTC().toISO(),
  };
  try {
    const { data, count } = (await fetchApi<ApiResponse<string>>({
      method: "POST",
      uri: `${apiVersion}/bookings/${facilityId}/recurring`,
      payload: payload,
    })) as ApiResponse<string>;

    return { data, count };
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateBookingComment = async (
  facilityId: string,
  data: IUpdateBookingCommentFormValues,
): Promise<boolean> => {
  try {
    const response = (await fetchApi<ApiSingleResponse<boolean>>({
      method: "POST",
      uri: `${apiVersion}/bookings/${facilityId}/comment`,
      payload: data,
    })) as ApiSingleResponse<boolean>;
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const unBookPlayerAdmin = async (
  bookingId: string,
  data: IAdminCancelBookingRequest,
  signal?: AbortSignal,
): Promise<boolean | null> => {
  try {
    const response = (await fetchApi<ApiSingleResponse<boolean>>({
      method: "POST",
      uri: `${apiVersion}/bookings/admin/${bookingId}/cancel`,
      signal,
      payload: data,
    })) as ApiSingleResponse<boolean>;
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getUpcomingBookingsCountForCourt = async (
  facilityId: string,
  courtId: string,
) => {
  const response = (await fetchApi<ApiSingleResponse<IBooking[]>>({
    method: "GET",
    uri: `${apiVersion}/bookings/admin/${facilityId}/upcomingbyfacilitycourt/${courtId}`,
  })) as ApiSingleResponse<IBooking[]>;

  return response.data.length;
};

export const reSendAccessEventForFutureBookingsToQT = async (
  facilityId: FacilityWithUtc["id"],
) => {
  await fetchApi({
    method: "PUT",
    uri: `${apiVersion}/bookings/internal/resendaccesseventforfuturebookings/${facilityId}/0`,
  });
};
