import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import {
  faArrowDownArrowUp,
  faBarsFilter,
  faCheck,
  faSort,
  faSortAlphaAsc,
  faSortAlphaDesc,
  faSortAsc,
  faSortDesc,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";

import {
  type AdminFacilityUserCoin,
  CoinType,
} from "../../../../../modules/player/models/Coins";

import { adminGetPlayerPath } from "../../../../../helpers/pathHelpers";

import {
  FilterListboxButton,
  FilterListboxOption,
  FilterListboxOptions,
} from "../../../../../components/FilterListbox";
import { ProfileImageWithFallback } from "../../../../../components/ProfileImageWithFallback";
import {
  Table,
  TableCurrencyCell,
  TableDateCell,
} from "../../../../../components/Table";

import { luxonDateFormat } from "../../../../../utils/dateFormats";

type Data = AdminFacilityUserCoin;

export const CoinsTable = ({ data }: { data: Data[] }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return <Table table={table} />;
};

const columnHelper = createColumnHelper<Data>();

const columns = [
  columnHelper.accessor(row => `${row.firstName} ${row.lastName}`, {
    id: "displayName",
    sortDescFirst: false,
    sortingFn: "text",
    header: ({ column }) => (
      <button
        type="button"
        onClick={
          (column.getCanSort() && column.getToggleSortingHandler()) || undefined
        }
        className="flex items-center gap-2"
      >
        <FormattedMessage id="common.player" />
        {column.getCanSort() && (
          <FontAwesomeIcon
            className={clsx(column.getIsSorted() && "text-primary")}
            icon={
              column.getIsSorted()
                ? column.getIsSorted() === "asc"
                  ? faSortAlphaAsc
                  : faSortAlphaDesc
                : faArrowDownArrowUp
            }
          />
        )}
      </button>
    ),
    cell: props => {
      const displayName = `${props.row.original.firstName} ${props.row.original.lastName}`;

      return (
        <Link
          to={adminGetPlayerPath(props.row.original.userId)}
          className="float-left flex items-center gap-4 font-inherit text-inherit"
        >
          <ProfileImageWithFallback
            className="size-5 flex-none"
            firstName={props.row.original.firstName}
            lastName={props.row.original.lastName}
            width=""
            title={displayName}
            src={props.row.original.profileImageUrl || undefined}
          />{" "}
          {displayName}
        </Link>
      );
    },
  }),
  columnHelper.accessor("type", {
    enableColumnFilter: true,
    filterFn: "weakEquals",
    header: ({ column }) => (
      <Listbox
        value={column.getFilterValue() ?? ""}
        onChange={val => {
          column.setFilterValue((currentValue: unknown) =>
            currentValue !== val ? val : "",
          );
        }}
      >
        <ListboxButton
          as={FilterListboxButton}
          data-active={column.getIsFiltered() ? "" : undefined}
        >
          <FormattedMessage id="common.type-of-coins" />

          <FontAwesomeIcon icon={faBarsFilter} />
        </ListboxButton>

        <ListboxOptions as={FilterListboxOptions} anchor="bottom">
          <ListboxOption value="" className="hidden" />

          {[CoinType.COIN, CoinType.ALLOWANCE].map(coinType => (
            <ListboxOption
              as={FilterListboxOption}
              value={coinType}
              key={coinType}
            >
              <FormattedMessage
                id={`coin.type.${CoinType[coinType]?.toLowerCase()}`}
              />

              {column.getFilterValue() === coinType && (
                <FontAwesomeIcon className="text-primary" icon={faCheck} />
              )}
            </ListboxOption>
          ))}
        </ListboxOptions>
      </Listbox>
    ),
    cell: props => (
      <FormattedMessage
        id={`coin.type.${CoinType[props.getValue()]?.toLowerCase()}`}
      />
    ),
  }),
  columnHelper.accessor("amount", {
    header: ({ column }) => (
      <div className="flex justify-end">
        <button
          type="button"
          className="flex items-center gap-2"
          onClick={column.getToggleSortingHandler()}
        >
          <FormattedMessage id="common.coins" />
          <FontAwesomeIcon
            className={clsx(column.getIsSorted() && "text-primary")}
            icon={
              column.getIsSorted()
                ? column.getIsSorted() === "asc"
                  ? faSortAsc
                  : faSortDesc
                : faSort
            }
          />
        </button>
      </div>
    ),
    cell: props => (
      <TableCurrencyCell value={props.getValue()} currency="Coins" />
    ),
  }),
  columnHelper.accessor("expiresUtc", {
    header: ({ column }) => (
      <button
        type="button"
        className="flex items-center gap-2"
        onClick={column.getToggleSortingHandler()}
      >
        <FormattedMessage id="common.expiration-date" />
        <FontAwesomeIcon
          className={clsx(column.getIsSorted() && "text-primary")}
          icon={
            column.getIsSorted()
              ? column.getIsSorted() === "asc"
                ? faSortAsc
                : faSortDesc
              : faSort
          }
        />
      </button>
    ),
    cell: props => (
      <TableDateCell
        facilityId={undefined}
        value={props.getValue()}
        dateFormat={luxonDateFormat}
      />
    ),
  }),
];
