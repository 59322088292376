import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { faCaretLeft, faCaretRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";

import { DateOnly } from "../../../../../models/DateOnly";
import { FacilityWithUtc } from "../../../../customer/models/Facility";
import { BookingType } from "../../../models/Booking";
import {
  ICalendarPayload,
  IScheduleResponse,
  IScheduleResponseGrouped,
  IScheduleSlot,
} from "../../../models/Calendar";

import { useCalendarDateChanger } from "../../../../../hooks/booking/useCalendarDateChanger";
import { useIsMobile } from "../../../../../hooks/common/useIsMobile";

import { ProgressSpinner } from "../../../../../components/ProgressSpinner";

import CalendarHead from "../CalendarHead";
import CustomerCalendarBody from "./content/CustomerCalendarBody";
import CustomerCalendarBodyMobile from "./content/mobile/CustomerCalendarBodyMobile";
import CustomerCalendarHeadMobile from "./content/mobile/CustomerCalendarHeadMobile";

interface IProps {
  selectedDate: DateOnly;
  facility: FacilityWithUtc;
  schedules: IScheduleResponse[];
  loading: boolean;
  courtId?: string;
  startTime?: DateTime;
  endTime?: DateTime;
  initialPayload?: ICalendarPayload;
  nrOfSlots?: number;
  bookingId?: string;
  onSelect: (payload: ICalendarPayload) => void;
  bookingType: BookingType;
}

const CustomerCalendarContent: React.FC<IProps> = ({
  selectedDate,
  facility,
  schedules,
  loading,
  courtId,
  startTime,
  // TODO: This will be used in the future
  // endTime,
  nrOfSlots,
  bookingId,
  onSelect,
  bookingType,
}) => {
  const {
    handleDecreaseHours,
    handleIncreaseHours,
    startIndex,
    prevEnabled,
    nextEnabled,
  } = useCalendarDateChanger(selectedDate, schedules.at(0));

  const isMobile = useIsMobile();

  const selectedDateOpenHours = facility?.openHours?.find(
    o => o.dayOfWeek === selectedDate.toDateTime().weekday % 7,
  );

  const makeSlotsFitCalendarslot = (
    slotLength: number,
    slots: IScheduleSlot[],
  ): IScheduleSlot[] | IScheduleSlot[][] => {
    if (slotLength === 30) {
      return slots;
    }

    const slotsPer30Min = 30 / slotLength;

    const grouped = slots.reduce(
      (acc, slot, i) =>
        (i % slotsPer30Min
          ? acc[acc.length - 1].push(slot)
          : acc.push([slot])) && acc,
      [],
    );

    return grouped;
  };

  const schedulesGrouped: IScheduleResponseGrouped[] = useMemo(
    () =>
      schedules.map(schedule => ({
        ...schedule,
        slots: makeSlotsFitCalendarslot(
          schedule.objectType?.defaultSlotLength,
          schedule.slots,
        ),
      })),
    [schedules],
  );

  return (
    <>
      {loading ? (
        <ProgressSpinner />
      ) : isMobile ? (
        <>
          <CustomerCalendarHeadMobile
            facilityId={facility.id}
            selectedDate={selectedDate}
            slots={schedules[0]?.slots || []}
            startIndex={startIndex}
          />
          <div className="sticky top-[104px] z-20 flex justify-between bg-gray-150 md:hidden">
            <div
              className="flex items-center justify-center px-2 py-2.5"
              onClick={() => prevEnabled && handleDecreaseHours(4)}
            >
              <FontAwesomeIcon
                className={`ml-2 text-2xl ${
                  !prevEnabled
                    ? "text-gray-500"
                    : "cursor-pointer text-blue-500"
                }`}
                icon={faCaretLeft}
              />
              <span
                className={`ml-2 text-sm ${
                  !prevEnabled
                    ? "text-gray-500"
                    : "cursor-pointer text-blue-500"
                }`}
              >
                <FormattedMessage
                  id="common.hours-short"
                  defaultMessage="{hours}tim"
                  values={{ hours: 4 }}
                />
              </span>
            </div>
            <div
              onClick={() => nextEnabled && handleIncreaseHours(4)}
              className="flex items-center justify-center px-2 py-2.5"
            >
              <span
                className={`ml-2 text-sm ${
                  !nextEnabled
                    ? "text-gray-500"
                    : "cursor-pointer text-blue-500"
                }`}
              >
                <FormattedMessage
                  id="common.hours-short"
                  defaultMessage="{hours}tim"
                  values={{ hours: 4 }}
                />
              </span>
              <FontAwesomeIcon
                className={`ml-2 text-2xl ${
                  !nextEnabled
                    ? "text-gray-500"
                    : "cursor-pointer text-blue-500"
                }`}
                icon={faCaretRight}
              />
            </div>
          </div>
          <CustomerCalendarBodyMobile
            schedules={schedulesGrouped}
            fullSchedule={schedules}
            nrOfSlots={nrOfSlots}
            bookingId={bookingId}
            courtId={courtId}
            startTime={startTime}
            bookingType={bookingType}
            startIndex={startIndex}
          />
        </>
      ) : (
        <>
          <CalendarHead
            slots={schedules[0]?.slots || []}
            facilityId={facility.id}
          />
          <CustomerCalendarBody
            schedules={schedulesGrouped}
            courtId={courtId}
            startTime={selectedDateOpenHours?.startTime.toDateTime()}
            endTime={selectedDateOpenHours?.endTime.toDateTime()}
            nrOfSlots={nrOfSlots}
            onSelect={onSelect}
            bookingType={bookingType}
          />
        </>
      )}
    </>
  );
};

export default CustomerCalendarContent;
