import { Fragment, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { InputSwitch } from "primereact/inputswitch";

import {
  BookableEntity,
  FacilityUpdateBookableEntities,
  FacilityWithUtc,
} from "../../../../../modules/customer/models/Facility";

import { useToaster } from "../../../../../hooks/common/useToaster";
import { useBookableTypes } from "../../../../../hooks/swr/useBookableTypes";
import { useSelectedFacility } from "../../../../../hooks/swr/useSelectedFacility";
import { useFormFieldValidator } from "../../../../../modules/customer/hooks/useFormFieldValidator";
import { useFormValidationSchema } from "../../../../../modules/customer/hooks/useFormValidationSchema";
import { useRefetchFacilityInformation } from "../../../../../modules/customer/hooks/useRefetchFacilityInformation";

import { getUpcomingBookingsCountForCourt } from "../../../../../modules/checkout/services/Booking";
import {
  deleteCourt,
  updateBookableEntity,
} from "../../../../../modules/customer/services/FacilityService";

import { Button } from "../../../../../components/Button";
import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";
import { ProgressSpinner } from "../../../../../components/ProgressSpinner";
import { TextInput } from "../../../../../components/TextInput";
import { SelectInput } from "../../../../../components/inputs/SelectInput";
import { FacilitySubmitResetButtons } from "../../../../../modules/customer/components/FacilitySubmitResetButtons";

import { useSelectedFacilityId } from "../../../../../recoil/selectedFacilityIdState";

export const FacilityBookableEntities = () => {
  const intl = useIntl();
  const { toastSuccess, toastError } = useToaster();
  const { editBookableEntitiesSchema } = useFormValidationSchema();
  const { facilityUpdatedCallback, refetchFacilities } =
    useRefetchFacilityInformation();
  const { selectedFacility: facility, mutate } = useSelectedFacility();
  const [resetClicked, setResetClicked] = useState(false);
  const selectedFacilityId = useSelectedFacilityId();
  const bookableEntityAbortController = useRef<AbortController>();

  const { bookableTypes, isLoading } = useBookableTypes(
    selectedFacilityId ?? undefined,
  );

  const [deleteCourtState, setDeleteCourtState] = useState<{
    courtId?: string;
    bookingsCount?: number;
    isLoading?: boolean;
  }>({});

  const copiedFacility = { ...facility };

  copiedFacility.bookableEntities = copiedFacility?.bookableEntities?.filter(
    court => !court.isDeleted,
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: copiedFacility as FacilityWithUtc,
    validationSchema: editBookableEntitiesSchema,
    onSubmit: async (facility: FacilityWithUtc) =>
      facilityUpdatedCallback(async () => {
        const updatedEntities = facility.bookableEntities
          .filter(({ updated }) => updated)
          .map(entity => ({
            ...entity,
            ...(entity.bookableEntityType
              ? {
                  bookableTypeId: entity.bookableEntityType?.id,
                  defaultNumberOfPlayers:
                    entity.bookableEntityType?.defaultNumberOfPlayers,
                }
              : {}),
          }));
        const existing = facility.bookableEntities.filter(
          ({ updated }) => !updated,
        );
        const bookableEntityModel: FacilityUpdateBookableEntities = {
          bookableEntities: existing.concat(updatedEntities),
        };
        bookableEntityAbortController.current = new AbortController();

        try {
          const bookableEntitiesResult = await updateBookableEntity(
            facility.id,
            bookableEntityModel,
            bookableEntityAbortController.current?.signal,
          );
          if (bookableEntitiesResult) {
            mutate(bookableEntitiesResult.data);
            refetchFacilities();
          }
          toastSuccess.changesSaved();
        } catch (e) {
          if (!bookableEntityAbortController.current?.signal.aborted)
            toastError.someChangesUpdateFailed();
        }
      }),
  });
  useEffect(() => () => bookableEntityAbortController.current?.abort(), []);

  const bookableEntities = formik.values?.bookableEntities;
  const { getFormErrorMessage } = useFormFieldValidator(formik);

  const addNewCourt = () => {
    formik.setFieldValue("bookableEntities", [
      ...formik.values.bookableEntities,
      { id: "", isHidden: false, name: "", bookableType: "", updated: true },
    ]);
  };

  const removeCourt = (key: number): void => {
    const bookableEntities = [...formik.values.bookableEntities];
    bookableEntities.splice(key, 1);
    formik.setFieldValue("bookableEntities", bookableEntities, true);
  };

  const setCourtValue = (key: number, valueName: string, newValue: any) => {
    const bookableEntities = [...formik.values.bookableEntities];
    const entityType = {
      ...bookableEntities[key],
      [valueName]: newValue,
      updated: true,
    };
    bookableEntities.splice(key, 1, entityType);
    formik.setFieldValue("bookableEntities", bookableEntities, true);
  };

  if (!facility) {
    return null;
  }

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        key={resetClicked.toString()}
        className="grid grid-cols-[1fr_1fr_auto] gap-x-6 gap-y-8 lg:grid-cols-[auto_2fr_2fr_1fr] lg:gap-x-8"
      >
        <h3 className="col-span-3 lg:col-span-4">
          <FormattedMessage id="facility-settings.my-courts" />
        </h3>
        {isLoading ? (
          <ProgressSpinner />
        ) : (
          bookableTypes && (
            <>
              {!!bookableEntities?.length &&
                bookableEntities.map((bookableEntity: BookableEntity, key) => (
                  <Fragment key={key}>
                    <div className="col-span-3 flex gap-2 lg:col-span-1 lg:mt-7">
                      <InputSwitch
                        name={`bookableEntities.[${key}].isHidden`}
                        checked={
                          !formik.values.bookableEntities?.[key].isHidden
                        }
                        onChange={e => {
                          formik.setFieldValue(
                            `bookableEntities.[${key}].isHidden`,
                            !e.value,
                          );
                        }}
                      />
                      <FormattedMessage
                        id={
                          !formik.values.bookableEntities?.[key].isHidden
                            ? "admin.courts.open"
                            : "admin.courts.closed"
                        }
                      />
                    </div>
                    <div>
                      <TextInput
                        key={bookableEntity.id}
                        name={`bookableEntities[${key}].name`}
                        label={intl.formatMessage({
                          id: "facility-settings.bookable.court-name",
                        })}
                        value={bookableEntity.name}
                        onBlur={formik.handleBlur}
                        onChange={e => {
                          formik.handleChange(e);
                          setCourtValue(key, "name", e.target.value);
                        }}
                      />
                      {getFormErrorMessage("bookableEntities", "name", key)}
                    </div>
                    <div>
                      <SelectInput
                        key={key}
                        label="Typ av bana"
                        value={
                          formik.values.bookableEntities?.[key].bookableType
                        }
                        translationName="common.court-type"
                        options={bookableTypes.map(({ name, id }) => ({
                          label: name,
                          value: id,
                        }))}
                        onChange={e =>
                          setCourtValue(key, "bookableType", e.value)
                        }
                      />
                      {getFormErrorMessage(
                        "bookableEntities",
                        "bookableType",
                        key,
                      )}
                    </div>

                    <FontAwesomeIcon
                      className="mt-8 cursor-pointer text-error"
                      onClick={() => {
                        if (bookableEntity.id) {
                          setDeleteCourtState({
                            courtId: bookableEntity.id,
                            isLoading: true,
                          });

                          getUpcomingBookingsCountForCourt(
                            facility.id,
                            bookableEntity.id,
                          )
                            .then(count => {
                              setDeleteCourtState({
                                courtId: bookableEntity.id,
                                bookingsCount: count,
                                isLoading: false,
                              });
                            })
                            .catch(() => {
                              setDeleteCourtState({});
                              toastError.unknown();
                            });
                        } else {
                          removeCourt(key);
                        }
                      }}
                      icon={faXmark}
                    />
                  </Fragment>
                ))}

              <div className="hidden lg:col-span-1 lg:block"></div>

              <Button
                className="col-span-1 self-end lg:col-span-1"
                onClick={addNewCourt}
                translationName="common.add-court"
                type="add"
              />

              <div className="hidden lg:col-span-2 lg:block"></div>

              <div className="col-span-3 lg:col-span-4">
                <FacilitySubmitResetButtons
                  onReset={() => {
                    formik.resetForm();
                    setResetClicked(resetClicked => !resetClicked);
                  }}
                  disabled={!formik.dirty}
                />
              </div>
            </>
          )
        )}
      </form>

      {deleteCourtState.courtId && (
        <ConfirmationDialog
          visible
          confirmButtonType="danger"
          loading={deleteCourtState.isLoading}
          onHide={() => setDeleteCourtState({})}
          onCancel={() => setDeleteCourtState({})}
          onSubmit={async () => {
            if (!deleteCourtState.courtId) {
              return;
            }

            try {
              setDeleteCourtState(v => ({ ...v, isLoading: true }));

              const response = await deleteCourt(
                facility.id,
                deleteCourtState.courtId,
              );

              if (!response.data) {
                throw new Error("Failed to delete court");
              }

              setDeleteCourtState({});
              toastSuccess.changesSaved();
              mutate(undefined);
            } catch {
              toastError.unknown();
            }
          }}
          title={intl.formatMessage({
            id: "admin.facility.settings.courts.delete-dialog.title",
          })}
          confirmText={intl.formatMessage({ id: "common.delete" })}
        >
          {typeof deleteCourtState.bookingsCount !== "undefined" &&
            deleteCourtState.bookingsCount > 0 && (
              <p className="font-semibold text-red-600">
                <FormattedMessage
                  id="admin.facility.settings.courts.delete-dialog.bookings-warning"
                  values={{ bookingsCount: deleteCourtState.bookingsCount }}
                />
              </p>
            )}
        </ConfirmationDialog>
      )}
    </>
  );
};
