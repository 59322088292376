import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import {
  faDownLeftAndUpRightToCenter,
  faRotateRight,
  faUpRightAndDownLeftFromCenter,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { DateTime, Interval } from "luxon";
import { Tooltip } from "primereact/tooltip";

import { adminGetActivitesCreateEventPath } from "../../../../../../helpers/pathHelpers";

import { useDateFormat } from "../../../../../../hooks/useDateFormat";
import { useAdminFilteredSchedules } from "../../../../hooks/calendar/useSchedules";

import {
  useAdminCalendarContext,
  useAdminCalendarContextState,
} from "../AdminCalendarContext";

export const AdminCalendarHead = () => {
  const { formatMessage } = useIntl();

  const { facilityId } = useAdminCalendarContextState();
  const { schedules, isLoading, isValidating, mutate } =
    useAdminFilteredSchedules();

  const slots = schedules[0]?.slots || [];

  const {
    state: { selectedDate, isExpanded },
    dispatch,
  } = useAdminCalendarContext();
  const { setZone, setLocale, df } = useDateFormat(facilityId);

  const firstSlot = slots.at(0);
  const lastSlot = slots.at(-1);

  let intervals: Interval[] = [];

  if (firstSlot && lastSlot) {
    intervals = Interval.fromDateTimes(
      setLocale(setZone(firstSlot.startTime)),
      setLocale(setZone(lastSlot.endTime)),
    ).splitBy({ hours: 1 });
  }

  const currentTime = DateTime.local();

  const firstInterval = intervals.at(0);
  const lastInterval = intervals.at(-1);

  let left: number | undefined = undefined;
  if (
    firstInterval &&
    lastInterval &&
    currentTime.hasSame(firstInterval.start, "day") &&
    currentTime < lastInterval.end &&
    currentTime >= firstInterval.start
  ) {
    const x = currentTime.diff(firstInterval.start, "hours");
    left = (x.hours / intervals.length) * 100;
  }

  return (
    <>
      <div className="mb-1 flex">
        <div className="flex w-[112px] flex-none items-center gap-1 pb-1">
          <button
            data-pr-tooltip={
              isExpanded
                ? formatMessage({
                    id: "admin.calendar.action-buttons.shrink",
                  })
                : formatMessage({
                    id: "admin.calendar.action-buttons.enlarge",
                  })
            }
            data-pr-position="right"
            type="button"
            className="action-button hidden size-8 items-center justify-center rounded border border-gray-50 transition-colors hover:border-pureblack 2xl:flex"
            onClick={() =>
              dispatch({
                type: "SET_IS_EXPANDED",
                payload: !isExpanded,
              })
            }
          >
            <FontAwesomeIcon
              size="xs"
              icon={
                isExpanded
                  ? faDownLeftAndUpRightToCenter
                  : faUpRightAndDownLeftFromCenter
              }
            />
          </button>

          <Link
            to={adminGetActivitesCreateEventPath(selectedDate.toISO())}
            data-pr-tooltip={formatMessage({
              id: "admin.calendar.action-buttons.create",
            })}
            className="action-button flex size-8 items-center justify-center rounded border border-gray-50 text-inherit transition-colors hover:border-pureblack"
          >
            <FontAwesomeIcon size="xs" icon={faPlus} />
          </Link>

          <button
            data-pr-tooltip={formatMessage({
              id: "admin.calendar.action-buttons.refresh",
            })}
            type="button"
            className="action-button flex size-8 items-center justify-center rounded border border-gray-50 transition-colors hover:border-pureblack"
            onClick={() => !isLoading && !isValidating && mutate(undefined)}
          >
            <FontAwesomeIcon
              size="xs"
              icon={faRotateRight}
              className={clsx((isLoading || isValidating) && "animate-spin")}
            />
          </button>

          <Tooltip position="top" target=".action-button" />
        </div>
        <div className="flex flex-grow items-center text-sm font-normal">
          {intervals.map((interval: Interval, index: number) => {
            return (
              <div
                key={interval.start?.toString()}
                className="flex-1 text-center even:pr-px"
              >
                {interval.start && df(interval.start, DateTime.TIME_SIMPLE)}
                {index !== 0 &&
                interval.start?.offset !==
                  intervals.at(index - 1)?.start?.offset
                  ? interval.start?.toFormat("Z")
                  : ""}
              </div>
            );
          })}
        </div>
      </div>

      {typeof left !== "undefined" && (
        <div className="pointer-events-none absolute bottom-2 left-[116px] right-5 top-2 z-20 select-none">
          <div
            data-before-content={df(currentTime, DateTime.TIME_SIMPLE)}
            className="absolute bottom-0 top-7 w-px bg-pureblack text-sm font-normal text-white before:absolute before:bottom-full before:-translate-x-1/2 before:rounded before:bg-pureblack before:px-1.5 before:content-[attr(data-before-content)]"
            style={{
              left: `${left}%`,
            }}
          ></div>
        </div>
      )}
    </>
  );
};
