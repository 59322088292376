import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import clsx from "clsx";
import { DateTime } from "luxon";
import { InputSwitch } from "primereact/inputswitch";
import { useEventListener, useOnClickOutside } from "usehooks-ts";

import { DateOnly } from "../../../../../models/DateOnly";
import {
  BookingType,
  type IBooking,
  type InitialAdminCalendarBookingData,
} from "../../../models/Booking";

import { useToaster } from "../../../../../hooks/common/useToaster";
import { useBooking } from "../../../../../hooks/swr/useBooking";
import { useFacility } from "../../../../../hooks/swr/useFacility";
import { useSlotBasePrice } from "../../../../../hooks/swr/useSlotBasePrice";
import { useCurrencyFormat } from "../../../../../hooks/useCurrencyFormat";
import { useDateFormat } from "../../../../../hooks/useDateFormat";
import { useQuery } from "../../../../../hooks/useQuery";
import { useAdminFilteredSchedules } from "../../../hooks/calendar/useSchedules";

import { rescheduleOpenBooking } from "../../../../../services/facilityOpenBookingsService";
import { rescheduleBooking } from "../../../services/Booking";

import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";
import { Dialog } from "../../../../../components/Dialog";

import { useAdminCalendarContext } from "./AdminCalendarContext";
import { AdminCalendarBody } from "./content/AdminCalendarBody";
import { AdminCalendarHead } from "./content/AdminCalendarHead";
import { AdminCalendarDetails } from "./details/AdminCalendarDetails";
import AdminCreateBookingForm from "./dialog-content/AdminCreateBookingForm";

const AdminCalendarContent = () => {
  const history = useHistory();
  const queryParams = useQuery();

  const {
    state: { bookingBeingRescheduled },
    dispatch,
  } = useAdminCalendarContext();
  const { mutate: mutateSchedules } = useAdminFilteredSchedules();

  const [selectedBookingId, setSelectedBookingId] = useState<IBooking["id"]>();
  const [initialBookingFormData, setInitialBookingFormData] =
    useState<InitialAdminCalendarBookingData>();

  const { booking } = useBooking(
    selectedBookingId,
    "participants,payments,individualprice",
  );

  // Set selected date from booking when has bookingId in query params
  useEffect(() => {
    if (!booking || !queryParams.has("bookingId")) {
      return;
    }

    dispatch({
      type: "SET_SELECTED_DATE",
      payload: DateOnly.fromDateTime(booking.startTime),
    });
  }, [booking, dispatch, queryParams]);

  useEffect(() => {
    const queryParamBookingId = queryParams.get("bookingId");

    if (queryParamBookingId) {
      setSelectedBookingId(queryParamBookingId);
    }
  }, [queryParams]);

  const bookingDetailsOverlayRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(bookingDetailsOverlayRef, e => {
    const target = e.target as HTMLDivElement;

    if (!selectedBookingId) {
      return;
    }

    if (
      target.closest(
        "[data-booking], .p-component-overlay, .base-Popup-root, .p-dropdown-panel, .p-datepicker, #headlessui-portal-root",
      )
    ) {
      return;
    }

    handleEditDialogClose(false, false);
  });

  useEventListener(
    "keydown",
    event => {
      if (!selectedBookingId) {
        return;
      }

      if (event.key !== "Escape") {
        return;
      }

      handleEditDialogClose(false, false);
    },
    undefined,
    { passive: true },
  );

  useEffect(() => {
    setInitialBookingFormData(undefined);
  }, [selectedBookingId]);

  const handleBookingClick = (bookingId: string) => {
    setSelectedBookingId(bookingId);
    history.replace(`?bookingId=${bookingId}`);
  };

  const handleCreateDialogClose = (refreshView = true) => {
    setSelectedBookingId(undefined);
    setInitialBookingFormData(undefined);

    if (refreshView) {
      mutateSchedules();
    }
  };

  const handleEditDialogClose = (
    refreshView = true,
    isEditDialogOpen = false,
  ) => {
    if (!isEditDialogOpen) {
      setSelectedBookingId(undefined);
      history.replace(history.location.pathname);
    }

    if (refreshView) {
      mutateSchedules();
    }
  };

  return (
    <>
      <div className="relative mt-4 rounded-lg bg-purewhite p-2 pr-5">
        <AdminCalendarHead />

        <AdminCalendarBody
          courtId={initialBookingFormData?.courtId}
          startTime={initialBookingFormData?.startTime}
          endTime={initialBookingFormData?.endTime}
          onSelect={setInitialBookingFormData}
          onBookingClick={handleBookingClick}
        />

        <aside
          className={clsx(
            "absolute right-0 top-0 z-30 h-full w-[526px] max-w-full transition-opacity",
            selectedBookingId ? "visible opacity-100" : "invisible opacity-0",
          )}
        >
          <div
            ref={bookingDetailsOverlayRef}
            className="sticky top-20 rounded-lg bg-purewhite shadow-lg lg:top-[126px]"
          >
            {selectedBookingId && (
              <AdminCalendarDetails
                bookingId={selectedBookingId}
                onSubmitted={handleEditDialogClose}
              />
            )}
          </div>
        </aside>
      </div>

      {!bookingBeingRescheduled && initialBookingFormData && (
        <Dialog visible onHide={() => handleCreateDialogClose(false)}>
          <AdminCreateBookingForm
            initialBookingData={initialBookingFormData}
            onSubmitted={handleCreateDialogClose}
          />
        </Dialog>
      )}

      {bookingBeingRescheduled && initialBookingFormData && (
        <AdminCalendarRescheduleDialog
          bookingBeingRescheduled={bookingBeingRescheduled}
          initialBookingFormData={initialBookingFormData}
          onCancel={() => {
            setInitialBookingFormData(undefined);
          }}
          onRescheduleComplete={() => {
            setInitialBookingFormData(undefined);
            dispatch({
              type: "SET_BOOKING_BEING_RESCHEDULED",
              payload: undefined,
            });
            mutateSchedules();
          }}
        />
      )}
    </>
  );
};

const AdminCalendarRescheduleDialog = ({
  bookingBeingRescheduled,
  initialBookingFormData,
  onCancel,
  onRescheduleComplete,
}: {
  bookingBeingRescheduled: IBooking;
  initialBookingFormData: InitialAdminCalendarBookingData;
  onCancel: () => void;
  onRescheduleComplete: () => void;
}) => {
  const intl = useIntl();
  const toaster = useToaster();

  const { facility } = useFacility(initialBookingFormData.facilityId);
  const { df } = useDateFormat(bookingBeingRescheduled.facilityId);
  const { cf } = useCurrencyFormat(facility?.localization?.currencyCode);
  const { slotBasePrice: currentSlotBasePrice } = useSlotBasePrice(
    bookingBeingRescheduled.facilityId,
    bookingBeingRescheduled.court.id,
    bookingBeingRescheduled.startTime,
    bookingBeingRescheduled.endTime,
  );
  const { slotBasePrice: newSlotBasePrice } = useSlotBasePrice(
    initialBookingFormData.facilityId,
    initialBookingFormData.courtId,
    initialBookingFormData.startTime,
    initialBookingFormData.endTime,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [sendMailToParticpants, setSendMailToParticpants] = useState(true);

  const newCourt = facility?.bookableEntities?.find(
    v => v.id === initialBookingFormData.courtId,
  );

  return (
    <ConfirmationDialog
      visible
      onHide={onCancel}
      onCancel={onCancel}
      onSubmit={async () => {
        setIsLoading(true);

        try {
          if (
            bookingBeingRescheduled.type === BookingType.Open &&
            bookingBeingRescheduled.externalServiceId
          ) {
            await rescheduleOpenBooking(
              bookingBeingRescheduled.facilityId,
              bookingBeingRescheduled.externalServiceId,
              {
                ...initialBookingFormData,
                sendEmailToParticipants: sendMailToParticpants,
              },
            );
          } else {
            await rescheduleBooking(
              bookingBeingRescheduled.id,
              initialBookingFormData,
              sendMailToParticpants,
            );
          }

          onRescheduleComplete();
        } catch {
          toaster.toastError.bookingRescheduleFailed();
          setIsLoading(false);
        }
      }}
      confirmText={intl.formatMessage({
        id: "admin.calendar.reschedule.confirm-dialog.confirm-button",
      })}
      loading={isLoading}
    >
      <h3 className="mb-5">
        <FormattedMessage id="admin.calendar.reschedule.confirm-dialog.title" />
      </h3>

      <div className="space-y-4 text-left text-sm">
        <div className="text-gray-700">
          <p className="ml-2">
            <FormattedMessage id="admin.calendar.reschedule.confirm-dialog.original-booking" />
          </p>
          <div className="flex justify-between rounded-lg border border-gray-50 px-2 py-3">
            <div className="">
              <p>
                <FormattedMessage id="common.date-and-time" />
              </p>
              <p>
                <FormattedMessage id="common.court" />
              </p>
              <p>
                <FormattedMessage id="admin.calendar.reschedule.confirm-dialog.price-label" />
              </p>
            </div>
            <div className="text-right">
              <p>
                {df(bookingBeingRescheduled.startTime, DateTime.DATETIME_MED)} -{" "}
                {df(bookingBeingRescheduled.endTime, DateTime.TIME_SIMPLE)}
              </p>
              <p>{bookingBeingRescheduled.court.name}</p>
              <p>
                {currentSlotBasePrice
                  ? cf(currentSlotBasePrice.price.valueInclTax)
                  : "..."}
              </p>
            </div>
          </div>
        </div>

        <div>
          <p className="ml-2 font-bold">
            <FormattedMessage id="admin.calendar.reschedule.confirm-dialog.new-booking" />
          </p>
          <div className="flex justify-between rounded-lg border border-primary px-2 py-3">
            <div className="">
              <p>
                <FormattedMessage id="common.date-and-time" />
              </p>
              <p>
                <FormattedMessage id="common.court" />
              </p>
              <p>
                <FormattedMessage id="admin.calendar.reschedule.confirm-dialog.price-label" />{" "}
              </p>
            </div>
            <div className="text-right font-bold">
              <p>
                {df(initialBookingFormData.startTime, DateTime.DATETIME_MED)} -{" "}
                {df(initialBookingFormData.endTime, DateTime.TIME_SIMPLE)}
              </p>
              <p>{newCourt?.name}</p>
              <p>
                {newSlotBasePrice
                  ? cf(newSlotBasePrice.price.valueInclTax)
                  : "..."}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 flex items-center justify-center gap-3">
        <label htmlFor="sendEmail">
          <FormattedMessage id="admin.send.participant.email" />
        </label>
        <InputSwitch
          inputId="sendEmail"
          checked={sendMailToParticpants}
          onChange={() => setSendMailToParticpants(v => !v)}
        />
      </div>
    </ConfirmationDialog>
  );
};

export default React.memo(AdminCalendarContent);
