import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { DateTime } from "luxon";

import { DateOnly } from "../../../../../../../models/DateOnly";
import { GenderType } from "../../../../../../../models/common";
import {
  GeneralActivities,
  GeneralActivityRequest,
} from "../../../../../../../modules/game/models/GeneralActivities";

import { eventSelectableImages } from "../../../../../../../helpers/activityHelper";

import { useToaster } from "../../../../../../../hooks/common/useToaster";

import { createGeneralActivity } from "../../../../../../../modules/game/services/GeneralActivities";

import { ImageSelector } from "../../../../../../../components/image/ImageSelector/ImageSelector";

import { Form } from "../Form";

interface Props {
  facilityId: string;
  prefilledValues?: GeneralActivities;
  startDate?: DateOnly;
  onCreate: (activityId: string, image?: string | File) => void;
}

export const CreateForm: React.FC<Props> = ({
  facilityId,
  prefilledValues,
  startDate,
  onCreate,
}: Props) => {
  const preSelectedImage = useMemo(
    () =>
      prefilledValues?.imageUrl ||
      eventSelectableImages[
        Math.floor(Math.random() * eventSelectableImages.length)
      ],
    [prefilledValues?.imageUrl],
  );

  const intl = useIntl();
  const [newImage, setNewImage] = useState<string | File>(preSelectedImage);
  const { toastError } = useToaster();

  const startTime = startDate
    ? startDate.toDateTime().setZone("local").set({ hour: 12 }).startOf("hour")
    : DateTime.now().set({ hour: 12 }).startOf("hour").plus({ days: 1 });

  const initialValues: GeneralActivityRequest = {
    facilityId,
    name: prefilledValues?.name ?? "",
    description: prefilledValues?.description ?? "",
    gender: prefilledValues?.gender ?? GenderType.Mix,
    startTime: startTime,
    endTime: startTime.plus({ hour: 1 }),
    price: prefilledValues?.price.valueInclTax ?? 0,
    numberOfParticipants: prefilledValues?.numberOfParticipants ?? 1,
    registrationOpenTo: startTime.minus({ hour: 2 }),
    courtIdsToPlay: prefilledValues?.courtIdsToPlay ?? [],
    openDoor: prefilledValues?.openDoor ?? false,
    isPinCodeEnabled: prefilledValues?.isPinCodeEnabled ?? true,
    pinCode: prefilledValues?.pinCode ?? "",
    openForRegistration: false,
    hidden: false,
    automaticCancellationAt: startTime.minus({ hour: 1 }),
    minNumberOfParticipants: prefilledValues?.minNumberOfParticipants ?? 0,
    priority: prefilledValues?.priority ?? null,
    coordinators: prefilledValues?.coordinators || [],
    discountPrices: prefilledValues?.discountPrices.length
      ? prefilledValues?.discountPrices.map(discount => ({
          discountAmount: discount.price.valueInclTax,
          membershipIds: discount.membershipIds,
        }))
      : [{ discountAmount: 0, membershipIds: [] }],
    minSkillLevel: prefilledValues?.minSkillLevel ?? 1,
    maxSkillLevel: prefilledValues?.maxSkillLevel ?? 10,
  };

  const submit = async (data: GeneralActivityRequest, onError: () => void) => {
    const cleanedData = {
      ...data,
      discountPrices: data.discountPrices.filter(
        discount => discount.membershipIds.length > 0,
      ),
    };

    try {
      const res = await createGeneralActivity(cleanedData);
      if (res?.isSuccessful && res?.data?.id) {
        onCreate(res.data.id, newImage);
      } else {
        onError?.();
        toastError.createActivityFailed();
      }
    } catch {
      onError?.();
      toastError.createActivityFailed();
    }
  };

  return facilityId ? (
    <div>
      <Form
        facilityId={facilityId}
        hasChange={!!prefilledValues || !!newImage}
        create
        initialValues={initialValues}
        imageSelector={
          <ImageSelector
            className="w-full max-w-[415px] sm:w-2/5"
            onSelect={setNewImage}
            pickerTitle={intl.formatMessage({
              id: "activity.image.selector.title",
            })}
            pickerDescription={intl.formatMessage({
              id: "activity.image.selector.description",
            })}
            imgSrc={preSelectedImage}
            selectableImages={eventSelectableImages}
          />
        }
        onSubmit={submit}
      />
    </div>
  ) : null;
};
