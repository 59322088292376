import { BuyableType } from "../modules/checkout/models/Payment";
import { IActivity } from "../modules/game/models/Activity";

export const sortSeries = (
  series: IActivity[],
  currentUserId: string,
): IActivity[] =>
  series.sort((x, y) => {
    const teamMembers = source =>
      source?.registeredTeams?.flatMap(
        team => team?.teamMembers?.map(m => m.id) ?? [],
      ) ?? [];

    if (teamMembers(y).includes(currentUserId)) return 1;
    if (teamMembers(x).includes(currentUserId)) return -1;

    return 0;
  });

export const activityIsFull = (activity: IActivity): boolean => {
  if (!activity) return;

  const limit = activity.maxParticipantsCount;

  switch (activity.buyableType) {
    case BuyableType.Series: {
      return activity.teams.length >= limit;
    }
    case BuyableType.Event: {
      return activity.participants.length >= limit;
    }
    default: {
      return true;
    }
  }
};

export const eventSelectableImages = [
  "https://court22storageprod.blob.core.windows.net/public-images/activities/cover/Activity_1.jpg",
  "https://court22storageprod.blob.core.windows.net/public-images/activities/cover/Activity_2.jpg",
  "https://court22storageprod.blob.core.windows.net/public-images/activities/cover/Activity_4.jpg",
  "https://court22storageprod.blob.core.windows.net/public-images/activities/cover/Activity_5.jpg",

  "https://court22storageprod.blob.core.windows.net/public-images/activities/cover/443337.jpg",
  "https://court22storageprod.blob.core.windows.net/public-images/activities/cover/451606.jpg",
  "https://court22storageprod.blob.core.windows.net/public-images/activities/cover/2149940874.jpg",
  "https://court22storageprod.blob.core.windows.net/public-images/activities/cover/2149940924.jpg",
];
