import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import {
  faImageSlash,
  faMars,
  faVenus,
  faVenusMars,
} from "@fortawesome/pro-regular-svg-icons";
import { faStar, faUsers } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { DateTime } from "luxon";
import { Skeleton } from "primereact/skeleton";

import { GenderType } from "../../../../../models/common";
import { BuyableType } from "../../../../../modules/checkout/models/Payment";
import type { IActivity } from "../../../../../modules/game/models/Activity";

import {
  adminGetActivitesEventPath,
  adminGetActivitesSeriesPath,
} from "../../../../../helpers/pathHelpers";

import { useDateFormat } from "../../../../../hooks/useDateFormat";

const utc = DateTime.utc();

export const AdminActivitesGridView = ({
  activities,
  isLoading,
}: {
  activities: IActivity[] | undefined;
  isLoading: boolean;
}) => {
  return (
    <div className="grid gap-x-8 gap-y-5 lg:grid-cols-2 xl:gap-x-14">
      {isLoading && (
        <>
          <Skeleton className="!h-44" />
          <Skeleton className="!h-44" />
          <Skeleton className="!h-44" />
        </>
      )}

      {activities &&
        activities?.map(activity => (
          <ActivityCard activity={activity} key={activity.id} />
        ))}
    </div>
  );
};

export const ActivityCard = ({ activity }: { activity: IActivity }) => {
  const { df } = useDateFormat(activity.facilityId);

  const url =
    activity.buyableType === BuyableType.Event
      ? adminGetActivitesEventPath(activity.id)
      : adminGetActivitesSeriesPath(activity.id);
  const isOpenForRegistration =
    activity.openForRegistration &&
    DateTime.utc() < activity.registrationOpenTo;
  const participants =
    activity.buyableType === BuyableType.Event
      ? activity.participants.length
      : activity.teams.length;
  const isActivityFull = participants >= activity.maxParticipantsCount;

  return (
    <Link
      to={url}
      className="flex gap-5 rounded border border-gray-50 py-5 pl-5 pr-2.5 font-inherit text-inherit transition-all hover:border-pureblack hover:bg-white hover:shadow-lg"
    >
      <div
        className={clsx(
          "relative hidden size-[140px] flex-none items-center justify-center overflow-hidden rounded text-sm font-bold sm:flex",
          !activity.imageUrl && "bg-white",
        )}
      >
        {activity.imageUrl ? (
          <img
            className="min-h-full min-w-full object-cover object-center"
            src={activity.imageUrl}
            alt={activity.name}
          />
        ) : (
          <FontAwesomeIcon icon={faImageSlash} size="4x" />
        )}

        {activity.priority && (
          <span className="absolute left-1.5 top-1.5 flex items-center gap-1 rounded-sm bg-purewhite px-1.5 py-0.5">
            <FontAwesomeIcon icon={faStar} />{" "}
            <FormattedMessage id="common.prioritized" />
          </span>
        )}

        <div className="absolute bottom-1.5 left-1.5 right-1.5 flex gap-1">
          <span
            className={clsx(
              "flex items-center gap-1 rounded-sm bg-purewhite px-1.5 py-0.5",
              isActivityFull && "text-green-700",
            )}
          >
            <FontAwesomeIcon icon={faUsers} />
            <FormattedMessage
              id="activity.participants.number"
              values={{
                numberOfParticipants: participants,
                maxNumberOfParticipants: activity.maxParticipantsCount,
              }}
            />
          </span>

          <span className="ml-auto flex items-center gap-1 rounded-sm bg-purewhite px-1.5 py-0.5">
            <FontAwesomeIcon
              fixedWidth
              icon={
                activity.gender === GenderType.Mix
                  ? faVenusMars
                  : activity.gender === GenderType.Male
                    ? faMars
                    : faVenus
              }
            />
          </span>
        </div>
      </div>
      <div className="flex flex-auto flex-col">
        <b className="line-clamp-2 self-start text-balance text-lg leading-tight text-inherit [overflow-wrap:anywhere] lg:text-xl xl:text-3xl">
          {activity.name}
        </b>
        <span
          className={clsx(
            "text-sm font-semibold",
            isOpenForRegistration ? "text-green-700" : "text-red-600",
          )}
        >
          {isOpenForRegistration ? (
            <FormattedMessage id="common.open-for-registration" />
          ) : (
            <FormattedMessage id="common.closed-for-registration" />
          )}
        </span>

        {activity.description && (
          <p className="mt-auto line-clamp-2 text-sm text-gray-700 [overflow-wrap:anywhere]">
            {activity.description}
          </p>
        )}
      </div>
      <div className="flex flex-col text-sm">
        <div
          className={clsx(
            "flex-initial self-end rounded border border-current px-3 py-[3px] font-bold",
            activity.buyableType === BuyableType.Event &&
              "bg-pureblack text-purewhite",
            activity.buyableType === BuyableType.Series && "text-pureblack",
          )}
        >
          {activity.buyableType === BuyableType.Event ? (
            <FormattedMessage id="common.event" />
          ) : (
            <FormattedMessage id="common.serie" />
          )}
        </div>

        <div className="mt-2.5 flex flex-auto flex-col gap-2.5 text-right font-semibold leading-tight">
          <div>
            <FormattedMessage id="common.starts" />:
            <br />
            <time dateTime={activity.startTime.toISO() ?? undefined}>
              {df(activity.startTime, {
                weekday: "short",
                month: "numeric",
                day: "numeric",
                year: activity.startTime.hasSame(utc, "year")
                  ? undefined
                  : "numeric",
              })}
            </time>
            {activity.buyableType === BuyableType.Event && (
              <>
                <br />
                {df(activity.startTime, DateTime.TIME_SIMPLE)}
              </>
            )}
          </div>

          <div className="mt-auto text-gray-700">
            <FormattedMessage id="common.last-day-of-registration" />:
            <br />
            <time dateTime={activity.registrationOpenTo.toISO() ?? undefined}>
              {df(activity.registrationOpenTo, DateTime.DATE_SHORT)}
            </time>
          </div>
        </div>
      </div>
    </Link>
  );
};
